import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Homepage from "./pages/homepage/homepage";
import Home from "./pages/homepage/home";
import About from "./pages/about/About";
import MenuPage from "./pages/menu/MenuPage";
import FoodTruck from "./pages/food_truck/FoodTruck";
import ContactUs from "./pages/contact_us/contactUs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Homepage/>} />
          <Route path='about' element={<About/>} />
          <Route path="menu" element={<MenuPage />} />
          <Route path="food_truck" element={<FoodTruck/>} />
          <Route path="contact" element={<ContactUs/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
