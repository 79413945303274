import bread_like from '../../asset/menu_Image/bread_like.png'
import small_bread from '../../asset/menu_Image/small_bread.svg'
import two_women from '../../asset/menu_Image/two_women.png'
import './food_truck.css'

const FoodTruck = () => {
  return (
    <div>
      <div className=" flex justify-center items-center h-[50vh] bg-no-repeat bg-cover bg-center bg-[url('asset/about_images/aboutFirstSection.png')] md:bg-[url('asset/about_images/aboutUsHomePage.png')]">
        <p className="text-[50px] leading-[50px] font-[800] pt-[5%] text-white capitalize hidden md:block">
          urban style food truck
        </p>
        <p className="text-[50px] leading-[50px] font-[800] pt-[5%] text-white capitalize text-center md:hidden">
        food truck
        </p>
      </div>

      <div className=" w-[90%]  mx-auto flex justify-center items-center md:block flex-col relative">
        <div className="flex flex-col text-justify  leading-[32.04px] text-[14px] md:text-[20px] font-[400] md:w-[50%] md:py-[50px] pt-[100px] pb-[50px]">
          <p>
           <span className="font-bold text-[#BA0606]">  Urban Style Food Truck</span> is a great appeal to Professionals and
            consumers with little time to eat who want a more extensive
            selection of meal alternatives in one visit. Our current
            distribution point for this is our single food truck, which are
            stationed at various locations to accommodate demand. During the
            week, the food truck will be placed in the business district, and on
            weekends, it will be located in areas where our clients reside and
            visit.
          </p>
          <p>
            What distinguishes our Food Truck from the competition is our
            commitment to provide our food and services in multiple locations
            for the convenience of our customers.
          </p>
          <p>
            Our food truck will be at these places on a rolling basis throughout
            the week, and we have adapted our schedule based on high-traffic and
            high-demand areas. There are also regular movement to guarantee that
            we are positioned where our clients are during the day (office
            locations during working hours, residential regions in the evening,
            and places with Clubs and Bars late at night)."
          </p>
        </div>

        <div className='absolute top-[-120px] md:top-[-172px] md:right-[-5%] '>
            <img src={bread_like} alt=""  className='hidden md:block'/>
            <img src={small_bread} alt="" className='md:hidden ' />
        </div>
      </div>

      <div className='w-full md:w-[90%] flex-col md:flex-row mx-auto flex justify-between gap-1'>
        <div className='w-[90%] mx-auto'>
            <img src={two_women} alt="" />
        </div>

        <div className='food_truck_bg w-full flex flex-col justify-start items-center pb-[80px] md:gap-[50px] '>
           
                <p className='md:text-[30px] text-[20px] font-[800] text-[#B40606] leading-[50px] capitalize md:py-[40px] pt-5'>our truck location</p>

                <div className=' flex flex-col gap-5 md:gap-8  text-center'>

                <div className='text-white grid md:grid-cols-3 grid-cols-1 justify-center items-center md:gap-2 gap-5' >
                    <p className='food_truck'>Downtown Toronto</p>
                    <p className='food_truck'>Etobicoke</p>
                    <p className='food_truck'>Mississauga</p>
                </div>

                <div className='text-white grid md:grid-cols-3 grid-cols-1 justify-between w-full md:w-[90%] gap-5  items-center mx-auto'>
                    <p className='food_truck'>Oakville</p>
                    <p className='food_truck'>North York</p>
                    <p className='food_truck'>Brampton</p>
                </div>

                <div className='text-white grid md:grid-cols-2 grid-cols-1 justify-center items-center w-full md:w-[80%]  gap-5 mx-auto'>
                    <p className='food_truck'>Scarborough</p>
                    <p className='food_truck'>Vaughn</p>
                   
                </div>
                </div>
                
        </div>
      </div>
    </div>
  );
};

export default FoodTruck;
