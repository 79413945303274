import { Link } from "react-router-dom";
import homepagefirst from "../../asset/images/homepageFirst.png";
import middleLogo from "../../asset/images/middleLogo.png";
import burrito1 from "../../asset/images/burrito.png";
import burritol2 from "../../asset/images/burrito (1).png";
import burritol3 from "../../asset/images/burrito (2).png";
import burritol4 from "../../asset/images/burrito (3).png";
import car from "../../asset/images/car.svg";
import foodtruck from "../../asset/images/foodtruck sectio img.png";
import instawhite from "../../asset/images/insta_white.svg";
import twitterwhite from "../../asset/images/twitter_WHITE.svg";
import youtubewhite from "../../asset/images/YOUTUBE_WHITE.svg";
import linkdnwhite from "../../asset/images/LINKLD_white.svg";
import tiktokwhite from "../../asset/images/TIKTOK_WHITE.svg";
import facebookwhite from "../../asset/images/facebook_white.svg";
import customer_support from "../../asset/images/customer_support.svg";
import burrito_logo3 from "../../asset/images/burrito logo3 3.png";
import email from "../../asset/images/email.svg";
import "./home.css";

const Homepage = () => {
  return (
    <div className="">
      {/* first section  */}
      <div className="bg py-[100px]">
        <div className=" w-full ml-0 md:w-[95%] md:ml-[5%]  flex items-center md:justify-between flex-col md:flex-row  ">
          <div className="w-[90%] mx-auto md:w-[50%] flex flex-col gap-5 md:gap-10">
            <div className="leading-[50px]">
              <div className="text-center text-[24px] md:text-[50px] text-black font-[800] border-b-2 border-[#C53F3F] pb-1 md:pb-2 leading-[29.5px] md:leading-[50px]">
                <p className="w-[80%] mx-auto  ">Welcome to</p>
                <p className="  text-[#B40606] w-full  md:w-[95%]  mx-auto">
                  Urban Style Burrito
                </p>
              </div>

              <p className=" font-[600] text-[16px] md:text-[24px] text-center leading-[32px] flex flex-col ">
                Embrace great taste while staying true to your{" "}
                <span className="text-[#B40606]">health goals.</span>
              </p>
            </div>

            <div className="w-full  flex items-center justify-center ">
              <button
                type="button"
                className=" px-[32px] capitalize py-2  bg-[#B40606] rounded-[30px] text-[18px] font-[700] text-white"
              >
                <Link to="/about">find out more</Link>
              </button>
            </div>
          </div>


          <div className="pt-5 md:pt-0   w-[90%] mx-auto md:w-auto ">
            <img src={homepagefirst} alt="" />
          </div>
        </div>
      </div>

      {/* second section  */}

      <div className="flex justify-center items-center bg2 relative ">
        <div className="w-[90%]  md:w-[65%] gap-5 flex flex-col justify-center items-center text-[14px]  md:text-[20px] text-center leading-[31.5px] font-[400] mx-auto pt-[200px] pb-[40px]">
          <p>
            At{" "}
            <span className="font-[700] text-[20px] text-[#B40606]">
              Urban Style Burrito
            </span>{" "}
            , We provide healthy, quality, authentic, and affordable food with
            an urban menu, every single food in our menu by design is to ensure
            that our customers meet their health goals. We believe great taste
            and flavor should not be sacrificed for health goals.
          </p>
          <div className="w-full  flex items-center justify-center ">
            <button
              type="button"
              className=" px-[32px] capitalize py-2 border-2  border-[#B40606] rounded-[30px] text-[18px] font-[700] text-[#B40606]"
            >
              <Link to="/about">About Us</Link>
            </button>
          </div>
        </div>
        <div className="  absolute top-[-80px]">
          <img src={middleLogo} alt="" />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-3  ">
        <div className="w-[90%] md:w-[60%] text-center leading-[31.5px] font-[400] mx-auto pt-[20px] pb-[40px] flex flex-col justify-center items-center gap-5 ">
          <p className="font-[600] text-[24px] md:text-[38px] leading-[32px]  text-[#F5992F] capitalize">
            our <span className="text-[#B40606]">offerings/menu</span>
          </p>
          <p className="font-[400] text-[14px] md:text-[20px] leading-[31.5px]">
            Our core offerings include a great selection of healthy, quality and
            fresh made-to-order{" "}
            <span className="font-[600] text-[#B40606]">
              Burrito, Burrito Bowl, Quesadillas, Salad Bowls, and tacos.
            </span>{" "}
            We use a combination of organic and non-organic ingredients. You
            also enjoy competitive prices on our menu accompanied with quality
            services.
          </p>
        </div>

        <div className="flex justify-center flex-col md:flex-row items-center gap-5 mb-[50px]  w-full">
          <div>
            <img src={burrito1} alt="" />
          </div>
          <div>
            <img src={burritol2} alt="" />
          </div>
          <div>
            <img src={burritol3} alt="" />
          </div>
          <div>
            <img src={burritol4} alt="" />
          </div>
        </div>

        <button
          type="button"
          className=" px-[32px] capitalize py-2 border-2  border-[#B40606] rounded-[30px] text-[18px] font-[700] text-[#B40606] hidden md:block"
        >
          <Link to="/about">VIEW full menu</Link>
        </button>
      </div>

      <div className=" flex justify-between  flex-col md:flex-row items-center h-full md:h-[534px] mt-10  text-white">
        <div className="w-full bg3 flex flex-col pl-[5%] pr-[3%]  justify-center items-start gap-[12px] md:py-[2%] py-[8%]  md:w-[53%] h-full">
          <div className="flex flex-col justify-center items-center md:items-start gap-4  w-full">
            <img src={car} alt="car" />
            <p className="flex flex-col   leading-[32px] font-[800] text-[28px] md:text-[40px] md:leading-[50px]  capitalize font-['baloo 2']">
              urban style <span>food truck</span>{" "}
            </p>
          </div>

          <p className="leading-normal md:leading-[32.04px] text-center md:text-justify md:text-[20px] text-[14px]">
            <span className="font-[700]  ">Urban Style Food Truck</span> is a
            great appeal to professionals and people with a limited time to eat
            who desire a more comprehensive range of food options in one stop.
            The present distribution point for this great taste is our sole food
            truck, which is located at various locations to meet demand.{" "}
          </p>
          <div className=" w-full flex justify-center items-center mt-5">
            <button
              type="button"
              className=" px-[32px] capitalize py-2 bg-white rounded-[30px] text-[18px] font-[700] text-[#B40606]"
            >
              <Link to="/about">Visit Our Food Trucks</Link>
            </button>
          </div>
        </div>

        <div className="w-full md:w-[47%] h-full ">
          <img
            src={foodtruck}
            alt="burrito_date"
            className="object-cover w-full h-full bg4"
          />
        </div>
      </div>

      <div className="bg4 mt-0 md:mt-10 pt-10">
        <div className="w-[90%] mx-auto flex justify-center flex-col md:flex-row">

          <div className="w-full md:border-r-2 py-0 md:py-[50px] flex justify-start items-center border-0">
            
          <div className=" flex  justify-start items-center gap-4 md:gap-10  w-full  ">
            <div >
            <img src={burrito_logo3} alt=""className="" />
            </div>
            

            <div className="flex flex-col justify-center items-start text-white  w-full">
              <img src={customer_support} alt="connect with us" />
              <p className="font-[800] text-[20px] md:text-[30px] leading-[32px] md:leading-[50px] ">
                connect with us
              </p>
              <div className="flex justify-center items-center gap-5 ">
                <img src={instawhite} alt="insta"  />
                <img src={facebookwhite} alt="facebok" />
                <img src={twitterwhite} alt="twitter" />
                <img src={tiktokwhite} alt="tiktok" />
                <img src={youtubewhite} alt="youtube" />
                <img src={linkdnwhite} alt="" />
              </div>
            </div>
          </div>
          </div>
          

          <div className="border-0 md:border-l-2 w-full  flex justify-end items-center py-10 text-white">
            <div className="flex flex-col justify-center  items-start ">
              <img src={email} alt="" />
              <p className="font-[800] text-[20px] md:text-[30px] leading-[50px] ">
                send us an email
              </p>
              <p className="font-[600] text-[14px] md:text-[20px] leading-[31.5px]">
                For enquiries, you can email us at info@urbanstyleburrito.ca
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
