import arrow from "../../asset/about_images/arrow.svg";
import vision from "../../asset/about_images/vision.svg";
import secondHomePage from "../../asset/about_images/secondHomePage.png";
import pinic from "../../asset/about_images/pinic.png";
import carIcon from "../../asset/about_images/car_icon.svg";
import cateen from "../../asset/about_images/foodCanteen.png";
import pnpwing from "../../asset/about_images/pngwing 1.png";
import Style_buritto from "../../asset/about_images/Style_buritto.png";
import triple from "../../asset/about_images/triple-taco-2_CLOSE-CROP.png";
import plate_rice from "../../asset/about_images/plate_rice.png";
import plate_small from "../../asset/about_images/plate_small.png";
import triple_small from "../../asset/about_images/triple_small.png";
import firstSmall from "../../asset/about_images/first.png";


import "./about.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="">
      <div className=" flex justify-center items-center h-[50vh] bg-no-repeat bg-cover bg-center bg-[url('asset/about_images/aboutFirstSection.png')] md:bg-[url('asset/about_images/aboutUsHomePage.png')]">
        <p className="text-[50px] leading-[50px] font-[800] pt-[5%] text-white">
          About Us
        </p>
      </div>

      <div className="about_bg2 ">
        <div className="md:w-[90%] w-full mx-auto grid justify-center items-center grid-cols-2 md:grid-rows-2   relative"> 
          <div className="flex flex-col justify-center items-center md:justify-start md:items-start   w-[90%] mx-auto pt-[30%] md:py-12 gap-5 col-span-2 md:col-span-1 ">
            <img src={arrow} alt="" />
            <p className="font-bold text-[30px] text-center md:text-justify  leading-[24px] capitalize text-[#B40606] ">
              our mission
            </p>

            <p className="font-[400] md:text-[20px] text-[14px] leading-[28.5px] text-center md:text-justify ">
              {" "}
              <span className="font-bold text-[#B40606]">
                Urban Style Burrito
              </span>{" "}
              has a mission to provide healthy, quality, authentic, and
              affordable food with an urban menu, every single food in our menu
              by design is to ensure that our customers meet their health goals.
              We believe great taste and flavor should not be sacrificed for
              health goals. With our quality offerings, you are sure of great
              taste while staying true to your health goals.
            </p>
          </div>

          <div className="flex flex-col justify-center items-center md:justify-start md:items-start  w-[90%] mx-auto md:ml-[10%] py-10 md:py-12 gap-5 col-span-2 md:col-span-1">
            <img src={vision} alt="" />
            <p className="font-bold text-[30px] text-center md:text-justify  leading-[24px] capitalize text-[#B40606] ">
              our vision
            </p>

            <p className="font-[400] text-[14px] md:text-[20px] leading-[28.5px] text-center md:text-justify ">
              {" "}
              We want to create a global restaurant chain that will be a
              household name in our industry. Even though Urban Style Burritos
              is kicking off as a food truck chain with seven locations around
              GTA, our vision is to build a brand that will have a presence in
              every continent and over 100 countries around the world with
              thousands of locations.
            </p>
          </div>

          <div className="col-span-2  h-full">
            <img src={secondHomePage} alt="" className="object-contain h-full hidden md:block" />
            <img src={pinic} alt="" className="object-contain h-full md:hidden" />
          </div>

          <div className="absolute top-[-100px] md:top-[-170px] right-0 px-10">
            <img src={pnpwing} alt="" className="" />
          </div>
        </div>
      </div>

      <div className="about_bg3">
        <div className="grid grid-cols-3 w-[90%] mx-auto md:h-[727px]  justify-center items-center gap-10 mt-12 md:mt-0">
          <div className="md:col-span-2  col-span-3 flex flex-col justify-center items-center md:justify-start md:items-start gap-5 w-full md:w-[90%]">
            <img src={carIcon} alt="" />
            <p className="font-bold md:text-[30px] text-[24px] leading-[24px] text-[#B40606]">
              Urban Style Food Truck
            </p>
            <div className="text-[14px] md:text-[20px] font-[400] flex flex-col text-justify gap-8  leading-[28px]">
              <p>
                <span className="font-bold">Urban Style Food Truck </span> is a
                great appeal to Professionals and consumers with little time to
                eat who want a more extensive selection of meal alternatives in
                one visit. Our current distribution point for this is our single
                food truck, which are stationed at various locations to
                accommodate demand. During the week, the food truck will be
                placed in the business district, and on weekends, it will be
                located in areas where our clients reside and visit.{" "}
              </p>
              <p>
                What distinguishes our Food Truck from the competition is our
                commitment to provide our food and services in multiple
                locations for the convenience of our customers.{" "}
              </p>
              <p>
                Our food truck will be at these places on a rolling basis
                throughout the week, and we have adapted our schedule based on
                high-traffic and high-demand areas. There are also regular
                movement to guarantee that we are positioned where our clients
                are during the day (office locations during working hours,
                residential regions in the evening, and places with Clubs and
                Bars late at night)."
              </p>
            </div>
            <div className="w-full  flex justify-center items-center md:justify-start md:items-start ">
              <button
                type="button"
                className=" px-[32px] capitalize py-2  border-[#B40606] border-4 rounded-[30px] text-[18px] font-[700] text-[#B40606]"
              >
                <Link to="/food_truck">Fine Nearby Food Truck</Link>
              </button>
            </div>
          </div>

          <div className="md:col-span-1 col-span-3">
            <img src={cateen} alt="" className="object-cover" />
          </div>
        </div>
      </div>

      <div className="about_bg4">
        <div className=" w-[90%] mx-auto grid grid-cols-12 py-[70px] gap-8 md:gap-0">

          <div className="col-span-12  md:col-span-7 justify-center items-center  flex  w-[100%] ">
            <div className="w-[60%] md:w-[80%] ">
              <img src={Style_buritto} alt="" className="hidden md:block" />
              <img src={firstSmall} alt="" className="md:hidden block  " />
            </div>
            <div className="w-[100%]  text-justify md:w-[100%] flex flex-col gap-5 ">
              <p className="text-[18px] md:text-[30px] text-white font-bold leading-6">
                We Are{" "}
                <span className="text-[#B40606]">Urban Style Burrito</span>{" "}
              </p>
              <p className="text-[14px] md:text-[20px] font-[400] leading-6">
                We deliver economical and high-quality Mexican inspired dishes
                for professionals and consumers with little time to dine who
                want a more diverse range of meal options in a single visit.
              </p>
            </div>
          </div>

          <div className="col-span-12  flex justify-start gap-3 md:gap-8 flex-col  items-center md:col-start-8 md:col-end-13 md:row-start-1 md:row-end-3">
            <div className="">
              <img src={triple} alt="" className="hidden md:block" />
              <img src={triple_small} alt="" className=" md:hidden" />
            </div>
            <div className="text-justify flex flex-col justify-center w-[95%] mx-auto items-start gap-5 ">
              <p className="font-bold text-[20px] md:text-[30px] leading-[24px] text-[#FFC727]">
                Stay Healthy
                <span className="text-[#B40606]">
                  {" "}
                  with Delicious Meals
                </span>{" "}
              </p>
              <p className="text-[14px] md:text-[20px] font-[400] text-justify">
                Enjoy fantastic tastes and flavors from our amazing and
                incredible meals while sticking to your health goals.{" "}
                <span className="font-bold ">At Urban Stlyle Burrito,</span>{" "}
                <span className="text-[#B40606]">
                  We believe that exceptional taste and quality should not be
                  traded for health goals.
                </span>
              </p>
            </div>
          </div>

          <div className="col-span-12  md:col-start-1 md:col-end-8  flex justify-center items-center flex-row-reverse md:row-start-2 md:row-end-3 gap-3 md:gap-0">
            <div className=" w-[55%] md:w-[100%] ">
              <img src={plate_rice} alt="" className="hidden md:block" />
              <img src={plate_small} alt="" className=" md:hidden" />

            </div>
            <div className="md:text-justify text-right flex flex-col  w-full md:justify-end md:items-end gap-2 md:gap-5">
              <p className="text-[20px] md:text-[30px] font-bold text-[#B40606]  leading-6">
                Our Promise <span className="text-black">To You</span>
              </p>
              <p className="text-[14px] md:text-[20px] font-[400] text-black leading-6 w-full">
                Healthy, quality, authentic, and affordable food with an{" "}
                <span className="text-[#B40606] font-bold">
                  urban menu. This is our promise to you.
                </span>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default About;
