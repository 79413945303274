import React from "react";
import NavbarComponent from "../../components/navbar/navbar.component";
import { Outlet } from "react-router";
import FooterComponent from "../../components/footer/footer.component";

const Home = () => {
  return (
    <div>
      <NavbarComponent />
      <Outlet />
      <FooterComponent />
    </div>
  );
};

export default Home;
