import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../asset/images/logoImg.svg";
import logotext from "../../asset/images/logotext.svg";
import { CloseOutlined } from "@ant-design/icons";
import toggler from "../../asset/images/toggler.svg";

import "./nav.css";
import { useState } from "react";

const NavbarComponent = () => {
  const [isnavbarOpen, setIsnavbarOpen] = useState(false);
  const navigate = useNavigate();
  const onClickHandler = () => setIsnavbarOpen(!isnavbarOpen);
  
  const navigator =(path)=>{
      navigate(`${path}`)
      setIsnavbarOpen(!isnavbarOpen)
  }
  return (
    <>
    <div className="py-5 px-5 md:hidden bg-none fixed top-0 bg-[#FFC727] bg-opacity-[70%]  w-full z-[1000]">
      <img src={toggler} alt="" onClick={onClickHandler}/>
    </div>
   
        <div className="w-full bg-[#B40606] md:block  hidden md:bg-[#FEFEFE] fixed overflow-hidden  top-0 z-[1000] md:h-[82px] h-[100vh] ">
      <div className="flex  flex-col md:flex-row justify-center items-center md:justify-between gap-10 md:gap-0 md:w-[90%] mx-auto  w-full">
        <div className="w-full md:w-[235px]  bg-white">
          <div className="flex justify-between items-center   md:m-0 w-[90%] mx-auto md:w-full ">
            <div
              className="flex justify-start md:justify-center items-center w-[221px] h-[full] cursor-pointer "
              onClick={() => navigate("/")}
            >
              <img src={logo} alt="" className="k" />
              <img src={logotext} alt="" className="k" />
            </div>
            <div className="md:hidden">
              <CloseOutlined className="text-[24px] text-[#000929]" onClick={onClickHandler} />
            </div>
          </div>
        </div>

        <div className="w-full  md:w-[80%] h-full">
          <ul className="flex md:flex-row flex-col  w-full  justify-center gap-[70px]  items-center h-full md:justify-start md:gap-10 md:items-start md:w-[70%]  mx-auto text-white md:text-[#B40606] md:font-[500] font-bold text-[14px] leading-[16.8px] text-center capitalize md:text-[18px]">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                    : ""
                }
              >
                home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                    : ""
                }
              >
                About us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/menu"
                className={({ isActive }) =>
                  isActive
                    ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                    : ""
                }
              >
                Our menu
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/food_truck"
                className={({ isActive }) =>
                  isActive
                    ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                    : ""
                }
              >
                food truck
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                    : ""
                }
              >
                contact us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>

    {/* mobile navbar */}
    {isnavbarOpen && (  
     <div className="w-full bg-[#B40606] md:block  md:bg-[#FEFEFE] fixed overflow-hidden bottom-0 top-0 z-[1000] md:h-[82px] h-[100vh] ">
     <div className="flex  flex-col md:flex-row justify-center items-center md:justify-between gap-10 md:gap-0 md:w-[90%] mx-auto  w-full">
       <div className="w-full md:w-[235px]  bg-white">
         <div className="flex justify-between items-center   md:m-0 w-[90%] mx-auto md:w-full ">
           <div
             className="flex justify-start md:justify-center items-center w-[221px] h-[full] cursor-pointer "
             onClick={() => navigate("/")}
           >
             <img src={logo} alt="" className="k" />
             <img src={logotext} alt="" className="k" />
           </div>
           <div className="md:hidden">
           <CloseOutlined className="text-[24px] text-[#000929]" onClick={onClickHandler} />
           </div>
         </div>
       </div>

       <div className="w-full  md:w-[80%] h-full">
         <ul className="flex md:flex-row flex-col  w-full  justify-center gap-[70px]  items-center h-full md:justify-start md:gap-10 md:items-start md:w-[70%]  mx-auto text-white md:text-[#B40606] md:font-[500] font-bold text-[14px] leading-[16.8px] text-center capitalize md:text-[18px]">
           <li>
             <NavLink
               to="/"
               onClick={()=>navigator('/')}
               className={({ isActive }) =>
                 isActive
                   ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                   : ""
               }
             >
               home
             </NavLink>
           </li>
           <li>
             <NavLink
               to="/about"
               onClick={()=>navigator('/about')}
               className={({ isActive }) =>
                 isActive
                   ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                   : ""
               }
             >
               About us
             </NavLink>
           </li>
           <li>
             <NavLink
               to="/menu"
               onClick={()=>navigator('/menu')}
               className={({ isActive }) =>
                 isActive
                   ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                   : ""
               }
             >
               Our menu
             </NavLink>
           </li>
           <li>
             <NavLink
               to="/food_truck"
               onClick={()=>navigator('/food_truck')}
               className={({ isActive }) =>
                 isActive
                   ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                   : ""
               }
             >
               food truck
             </NavLink>
           </li>
           <li>
             <NavLink
               to="/contact"
               onClick={()=>navigator('/contact')}
               className={({ isActive }) =>
                 isActive
                   ? " md:text-[#F5992F] font-bold md:border-b-[3px] md:border-[#F5992F]  "
                   : ""
               }
             >
               contact us
             </NavLink>
           </li>
         </ul>
       </div>
     </div>
   </div>
    )}
    </>
  );
};

export default NavbarComponent;
