import plate_rice from "../../asset/about_images/plate_rice.png";
import pngwing from "../../asset/menu_Image/pngwing.png";
import triple from "../../asset/about_images/triple-taco-2_CLOSE-CROP.png";
import pngwing2 from "../../asset/menu_Image/pngwing 2.png";
import bread_like from "../../asset/menu_Image/bread_like.png";
import tortilla from "../../asset/menu_Image/Tortilla chips 2.png";
import loaded_fries from "../../asset/menu_Image/Mask group.png";
import kombucha from "../../asset/menu_Image/Kombucha.png";
import pepsi from "../../asset/menu_Image/pepsi.png";
import rice from '../../asset/menu_Image/rice_small.svg'

import "./menuPage.css";
const MenuPage = () => {
  return (
    <div>
      <div className=" flex justify-center items-center h-[50vh] bg-no-repeat bg-cover bg-center bg-[url('asset/about_images/aboutFirstSection.png')] md:bg-[url('asset/about_images/aboutUsHomePage.png')]">
        <p className="text-[50px] leading-[50px] font-[800] pt-[5%] text-white capitalize">
          our menu
        </p>
      </div>

      <div className=" w-[90%] mx-auto  relative flex flex-col justify-center items-center">
        <div
          className="w-full md:w-[60%] mx-auto text-center text-[14px] md:text-[20px] leading-6 flex justify-center items-center gap-5 flex-col 
         md:py-[80px] pt-[150px] pb-[0px] "
        >
          <p>
            Our core offerings include a great selection of healthy, quality and
            fresh made-to-order{" "}
            <span className="text-[#B40606] font-bold">
              Burrito, Burrito Bowl, Quesadillas, Salad Bowls, and tacos.
            </span>
          </p>
          <p>
            We use a combination of organic and non-organic ingredients. You
            also enjoy competitive prices on our menu accompanied with quality
            services.
          </p>
          <p className="font-[600]">
            Our Food Truck offers a wide range of food options including:
          </p>
        </div>
        <div className="top-[-110px]   md:top-[-177px] md:right-0 absolute">
          <img src={plate_rice} alt="" className="hidden md:block" />
          <img src={rice} alt="" className="w-full md:hidden" />
        </div>
      </div>


      <div className="flex flex-col gap-5 ">
        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col ">
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[50%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px]  md text-[20px]:md:text-[40px]">
                  Urban Style Burrito
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    Urban Style Burrito
                  </span>{" "}
                  is full of goodness, the protein-rich beans and rich whole
                  grain rice with a mix of green veggies offer with excellent
                  dressing offers a healthy fill for a perfect meal.
                </p>
              </div>
            </div>

            <div className="bg_white h-[200px] w-full md:h-full md:w-[50%] flex justify-center items-center">
              <div className="px-12 md:p-0">
                <img src={pngwing} alt="" className="object-cover h-full w-full" />
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center flex-col-reverse md:flex-row md:h-[303px] ">
            <div className="bg_white h-[200px] w-full md:h-full md:w-[52%] flex justify-center items-center">
              <div className="h-full pt-5">
                <img src={plate_rice} alt="" className="object-cover  h-full " />
              </div>
            </div>
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[48%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Burrito Bowl
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    Our Urban style burrito bowl
                  </span>{" "}
                  is a combination of healthy and great taste, full of organic
                  veggies and greens that will serve as a perfect filling meal.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col ">
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[50%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Tacos
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    Our Urban style burrito bowl
                  </span>{" "}
                  is a combination of healthy and great taste, full of organic
                  veggies and greens that will serve as a perfect filling meal.
                </p>
              </div>
            </div>

            <div className="bg_white h-[200px] w-full md:h-full md:w-[50%] flex justify-center items-center">
              <div className="h-full px-10 md:px-0">
                <img src={triple} alt="" className="object-cover w-full h-full"/>
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col-reverse ">
            <div className="bg_white h-[200px] w-full md:h-full md:w-[52%] flex justify-center items-center">
              <div className="h-full p-10 md:p-0 md:pt-5">
                <img src={pngwing2} alt="" className="object-cover h-full w-full" />
              </div>
            </div>
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[48%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Salad Bowls
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    {" "}
                    Healthy salad bowls
                  </span>{" "}
                  with sastifying ingredients and nourishment. Urban Style Salad
                  bowl allows you to eat a balanced diet with low calories.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col ">
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[50%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Quesadillas
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    With the combination of green veggies,
                  </span>{" "}
                  beans, and lean meats, this meal will easily meet your health
                  goals. Urban style Quesadillas provide a dish with high
                  protein and calcium and are also a good source of iron and
                  fiber.
                </p>
              </div>
            </div>

            <div className="bg_white h-[200px] w-full md:h-full md:w-[50%] flex justify-center items-center">
              <div className="h-full  md:p-0">
                <img src={bread_like} alt="" className="object-cover w-full h-full"/>
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col-reverse ">
            <div className="bg_white h-[200px] w-full md:h-full md:w-[52%] flex justify-center items-center">
              <div className="h-full md:p-0">
                <img src={tortilla} alt="" className="object-cover w-full h-full" />
              </div>
            </div>
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[48%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Tortilla Chips
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    Urban style blue Tortilla chips
                  </span>{" "}
                  offer you a full feel for a long time with a high source of
                  vitamins.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col ">
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[50%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Loaded Fries
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    Great taste with flavourful toppings.
                  </span>{" "}
                  Urban Style Loaded fries is our most popular plate filler that
                  promises a great guest experience.
                </p>
              </div>
            </div>

            <div className="bg_white h-[200px] w-full md:h-full md:w-[50%] flex justify-center items-center">
              <div className="h-full pt-3  md:p-0">
                <img src={loaded_fries} alt="" className="object-cover w-full h-full" />
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto">
          <div className=" w-[100%] flex justify-center  md:flex-row md:h-[303px] flex-col-reverse ">
            <div className="bg_white h-[200px] w-full md:h-full md:w-[52%] flex justify-center items-center">
              <div className=" md:p-0  w-full h-full">
                <img src={kombucha} alt="" className="object-cover w-full" />
              </div>
            </div>
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10  md:w-[48%] ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">
                  Urban Style Kombucha
                </p>
                <p>
                  {" "}
                  <span className="text-[14px] md:text-[20px] text-[#FFC727] font-[500] leading-[32.04px] text-justify">
                    {" "}
                    Our well flavored kombucha
                  </span>{" "}
                  loaded with probiotics and antioxidants continues to remain
                  the urban style beverage to achieve your health goals.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-[90%] w-full mx-auto ">
          <div className=" w-[100%] flex   md:flex-row md:h-[303px] flex-col ">
            <div className=" h-[200px] md:h-full  bg_red text-white flex flex-col justify-center items-start gap-5 w-full py-10   ">
              <div className="w-[90%] mx-auto">
                <p className="font-bold leading-[45.5px] text-[20px] md:text-[40px]">Drinks</p>
                <p>
                  Add a can or bottle of cold beverage to pair with your meal.
                  Choose from water and variety of pop and juices, Urban Style
                  Kombucha, Coke products, and Lemonade
                </p>
              </div>
            </div>

            <div className="bg_white h-[200px] md:h-full w-full flex justify-center items-center ">
              <div className="h-full">
                <img src={pepsi} alt="" className="object-cover w-full h-full"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
