
import skip from "../../asset/images/skipTheDishes.svg";
import uberEats from "../../asset/images/UberEats.svg";
import doorBash from "../../asset/images/doorDash.svg";
import insta from "../../asset/images/instargram.svg";
import twitter from "../../asset/images/twitter (3).svg";
import youtube from "../../asset/images/youtube (2).svg";
import linkdn from "../../asset/images/linkld.svg";
import tiktok from "../../asset/images/tiktok.svg";
import facebook from "../../asset/images/facebook (2).svg";

const FooterComponent = () => {
  return (
    <footer className="w-full bg-[#F0F3F6] md:bg-white ">
      <div className="w-[90%] mx-auto ">
      <div className="grid md:grid-cols-4 grid-cols-1 justify-start md:justify-center items-start w-full  gap-10  pt-[40px] pb-[100px]">
        <div className="flex flex-col justify-center items-start capitalize gap-10 ">
          <div>
            <p className="text-[#B40606] font-[600] text-[16px] md:text-[18px] ">
              office location:
            </p>
          </div>
          <div className="flex flex-col justify-center items-start md:text-[16px] text-[12px]">
            <p>75 enford Crescent,</p>
            <p>Brampton, Ontario L7A 4C8.</p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-start capitalize gap-10  ">
          <div>
            <p className="text-[#B40606]  font-[600] md:text-[18px] text-[16px]">
              Opening hours
            </p>
          </div>
          <div className="flex flex-col justify-center items-start gap-2 text-[12px] md:text-[16px]">
            <p>monday to friday</p>
            <p>11:00 am to 11:00pm</p>
            <p>saturdays & sunday</p>
            <p>10:00 am to 11:00pm</p>
          </div>
        </div>

        <div className="flex flex-col md:justify-center md:items-center justify-start items-start capitalize gap-5 ">
          <div>
            <p className="md:text-[24px] text-[16px] font-bold leading-[50px] ">
              Available on
            </p>
          </div>
          <div className="flex flex-col   w-[60%] gap-8   md:justify-center md:items-center justify-start items-start">
            <img src={skip} alt="" />
            <img src={doorBash} alt="" />
            <img src={uberEats} alt="" />
          </div>
        </div>

        <div className="flex flex-col justify-center items-start capitalize gap-5 ">
          <div>
            <p className="text-[#B40606]  font-[600] text-[18px]">
              let's connect
            </p>
          </div>
          <div className="grid grid-cols-3 justify-between  w-[60%] items-center gap-10">
            <img src={insta} alt="insta" />
            <img src={facebook} alt="facebok" />
            <img src={twitter} alt="twitter" />
            <img src={tiktok} alt="tiktok" />
            <img src={youtube} alt="youtube" />
            <img src={linkdn} alt="" />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center  border-t-2 border-[#E2E2E2] py-7 text-[16px]">
        <p>2023 Urban Style Burrito. All rights reserved</p>
        <div className="flex justify-center items-center pt-2 text-[14px] ">
          <p className=" p-2 ">Privacy Policy</p>
          <p className="border-l-2  border-[#AAAAAA] p-2 ">Terms of use</p>
        </div>
      </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
