import triple from "../../asset/about_images/triple-taco-2_CLOSE-CROP.png";
import skip from "../../asset/images/skipTheDishes.svg";
import uberEats from "../../asset/images/UberEats.svg";
import doorBash from "../../asset/images/doorDash.svg";

import {  Form, Input } from "antd";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
const ContactUs = () => {
  const [name, setName] = useState("");
  console.log(name)
  return (
    <div className="bg-[#7B7F86]/[0.13] md:bg-white">
      <div className=" flex justify-center items-center h-[50vh] relative bg-no-repeat bg-cover bg-center bg-[url('asset/about_images/aboutFirstSection.png')] md:bg-[url('asset/about_images/aboutUsHomePage.png')]">
        <p className="text-[50px] leading-[50px] font-[800] pt-[5%] text-white capitalize">
          contact us
        </p>
        <div className="absolute bottom-[-120px]  right-0">
          <img src={triple} alt="" className="" />
        </div>
      </div>

      <div className=" w-[90%] mx-auto flex justify-center flex-col md:flex-row py-[130px]">
        <div className="flex-col flex gap-6  w-full">
          <div className="flex flex-col justify-center items-start capitalize gap-3   ">
            <div>
              <p className="text-[#B40606] font-[600] text-[16px] md:text-[18px] ">
                office location:
              </p>
            </div>
            <div className="flex flex-col justify-center font-[500] leading-[31.5px] items-start md:text-[16px] text-[12px]">
              <p>75 enford Crescent,</p>
              <p>Brampton, Ontario L7A 4C8.</p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start capitalize gap-3  ">
            <div>
              <p className="text-[#B40606]  font-[600] md:text-[18px] text-[16px]">
                Opening hours
              </p>
            </div>
            <div className="flex flex-col justify-center font-[500] items-start gap-2 text-[12px] md:text-[16px]">
              <p>monday to friday</p>
              <p>11:00 am to 11:00pm</p>
              <p>saturdays & sunday</p>
              <p>10:00 am to 11:00pm</p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start capitalize gap-3  ">
            <p className="text-[#B40606]  font-[600] md:text-[18px] text-[16px]">
              Food Truck Location
            </p>
            <div className="font-[500] md:text-[18px] text-[16px] flex flex-col gap-3  text-center justify-center items-start w-[80%] md:w-[50%]">
              <div className="flex  justify-start items-start w-full  gap-5">
                <p className="">Downtown Toronto</p>
                <p className="">Etobicoke</p>
              </div>

              <div className="flex  justify-between items-start w-full ">
                <p className="">Mississauga</p>
                <p className="">Oakville</p>
                <p className="">North York</p>
              </div>

              <div className="flex  justify-between items-start w-full ">
                <p className="b">Brampton</p>
                <p className="">Scarborough</p>
                <p className="">Vaughn</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col  justify-start items-start capitalize  gap-2">
            <div>
              <p className="md:text-[24px] text-[16px] font-bold leading-[40px] ">
                Available on
              </p>
            </div>
            <div className="flex  flex-col md:flex-row  w-[60%] gap-8  justify-start items-start">
              <img src={skip} alt="" />
              <img src={doorBash} alt="" />
              <img src={uberEats} alt="" />
            </div>
          </div>
        </div>
        {/* form  */}
        <div className=" bg-white w-full mt-[50px] md:mt-0 ">
          <div className=" w-[95%] mx-auto py-5 shadow-lg">
            <p className="font-[600] text-[18px] md:text-[22px] leading-[21.45px] pl-5 text-[#8B2934]">
              Feedback/Enquiry Form
            </p>

            <Form
              layout="vertical"
              autoComplete="off"
              className=" w-[80%] mx-auto pt-5 "
            >
              <Form.Item
                label={
                  <p className="text-[14px] md:text-[16px] capitalize font-bold">your name</p>
                }
                name="full name"
                rules={[
                  { required: true, message: "Please input your full name!" },
                ]}
              >
                <Input
                  className=" p-2"
                  placeholder="Enter Full name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label={
                  <p className="text-[14px] md:text-[16px] capitalize font-bold">
                    your Location
                  </p>
                }
                name="location"
                rules={[
                  { required: true, message: "Please input your Location!" },
                ]}
              >
                <Input
                  className="hover:outline-2 outline-amber-800 p-2"
                  placeholder="Give us your location"
                />
              </Form.Item>

              <Form.Item
                label={
                  <p className="text-[14px] md:text-[16px] capitalize font-bold">
                    email address
                  </p>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email address",
                  },
                ]}
              >
                <Input
                  className="hover:outline-2 outline-amber-800 p-2"
                  placeholder="Enter your email address"
                  type="email"
                />
              </Form.Item>

              <Form.Item
                label={
                  <p className="text-[14px] md:text-[16px] capitalize font-bold">
                    your message
                  </p>
                }
                name="message"
                rules={[{ required: true, message: "Can't be empty" }]}
              >
                <TextArea rows={2}
                  className="hover:outline-2 outline-amber-800 p-2"
                  placeholder="Give us your location"
                />
              </Form.Item>

              <Form.Item>
                <button
                  className="w-full rounded-[30px] py-3 font-[800] text-[18px] bg-[#B40606] text-white hover:bg-[red]"
                  
                >
                  Submit
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
